<template>
  <the-base-table
    ref="baseTable"
    url="ms_bonus/"
    singularName="Bonificación servicio médico"
    pluralName="Bonificación de servicios médicos"
    :headers="headers"
    :filters="filters"
    :objectEdited.sync="msBonusEdit"
    :objectDefault="msBonusDefault"
    :showActionsColumn="false"
    :edit="false"
    :remove="false"
  >
    <template v-slot:form="{ isEditing }">
      <ms-bonus-form
        :is-editing="isEditing"
        :ms_bonus="msBonusEdit"
        @canceled="stopEditing()"
        @saved="refresh()"
        @error="error($event)"
      />
    </template>

    <template v-slot:toolbar-append>
      <v-container>
        <v-layout wrap>
          <v-flex xs12 sm6 md2>
            <v-autocomplete
              name="year"
              :items="years"
              :loading="loadingYear"
              v-model="filter.year"
              :value="filter.year"
              label="Año de validez"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template v-slot:row="{ item }">
      <td class="text-center">{{ item.id }}</td>
      <td class="text-center">{{ item.percentage | percentage }}</td>
      <td class="text-center">{{ item.year }}</td>
      <td>{{ item.get_service_display }}</td>
      <td class="text-center" v-if="item.is_active">
        <v-icon large color="green darken-2">check_circle_outline</v-icon>
      </td>
      <td class="text-center" v-if="!item.is_active">
        <v-icon large color="red darken-2">highlight_off</v-icon>
      </td>
    </template>
  </the-base-table>
</template>

<script>
import TheBaseTable from '@/components/base/BaseTable'
import MsBonusForm from '@/components/ms_bonus/MSBonusForm'
import Utils from '@/utils'
export default {
  data: () => ({
    years: [],
    btypeCategories: [],
    loadingBtypeCategory: true,
    loadingYear: true,
    filter: {
      year: null,
    },
    msBonusEdit: {
      percentaje: '',
      year: '',
      service: '',
    },
    headers: [
      { text: 'ID', value: 'id', align: 'center', width: '10%' },
      {
        text: 'Porcentaje de bonificación (%)',
        value: 'percentage',
        align: 'center',
        width: '20%',
      },
      { text: 'Válido desde', value: 'year', align: 'center' },
      {
        text: 'Servicio médico asociado',
        value: 'get_service_display',
        align: 'center',
      },
      { text: 'Activo', value: 'is_active', align: 'center' },
    ],
  }),
  mounted() {
    if (this.axios.defaults.headers.common.Authorization) {
      this.axios
        .get(this.$api('ms_bonus/years'))
        .then(data => {
          data.data.forEach(Year => {
            this.years.push({
              value: Year.year,
              text: Year.year,
            })
          })
          this.years.push({
            value: null,
            text: 'Todos',
          })
          this.loadingYear = false
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    filters() {
      return this.filter
    },
    msBonusDefault() {
      if (
        this.$store.getters['auth/user'].active_role === Utils.userRoles[2].id
      ) {
        return {
          id: -1,
          percentage: 0,
          year: '',
          get_service_display: '',
        }
      }
      return undefined
    },
  },
  methods: {
    refresh() {
      this.$refs.baseTable.isEditing = false
      this.$refs.baseTable.fetch()
    },
    stopEditing() {
      this.$refs.baseTable.isEditing = false
    },
    error(event) {
      console.log(event)
    },
  },
  components: {
    TheBaseTable,
    MsBonusForm,
  },
}
</script>
