<template>
  <the-base-form
    :url="url"
    :object="ms_bonus"
    :is-editing="isEditing"
    singularName="Bonificación para servicio médico"
    @canceled="$emit('canceled')"
    @saved="$emit('saved')"
    @error="$emit('error', $event)"
  >
    <v-flex xs12 sm12 md6>
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <ValidationProvider
            name="Fecha inicio de validez"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              :value="computedDateFormatted"
              label="Fecha inicio de validez"
              hint="dd-mm-aaaa"
              persistent-hint
              prepend-icon="event"
              readonly
              v-bind="attrs"
              v-on="on"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </template>
        <v-date-picker
          locale="es-CL"
          v-model="ms_bonus.year"
          no-title
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs12 sm12 md6>
      <ValidationProvider
        name="Porcentaje de bonificación"
        rules="required|between:0,100.00"
        v-slot="{ errors }"
      >
        <v-text-field
          type="number"
          :value="rawPercentage"
          v-model="rawPercentage"
          label="Porcentaje de bonificación"
          hint="%"
          prefix="%"
          persistent-hint
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <ValidationProvider
        name="Servicio médico"
        rules="required"
        v-slot="{ errors }"
      >
        <v-select
          name="medical_service"
          :items="medical_services"
          v-model="ms_bonus.service"
          :value="ms_bonus.service"
          label="Servicio médico asociado"
          hint="Servicio médico"
          :error-messages="errors"
        ></v-select>
      </ValidationProvider>
    </v-flex>
  </the-base-form>
</template>

<script>
import TheBaseForm from '@/components/base/BaseForm'
import Utils from '@/utils'
export default {
  data: () => ({
    medical_services: [],
    menu2: false,
    rawPercentage: null,
  }),
  components: {
    TheBaseForm,
  },
  props: {
    ms_bonus: Object,
    isEditing: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    url() {
      return 'ms_bonus/'
    },
    computedDateFormatted() {
      return Utils.formatDate(this.ms_bonus.year)
    },
  },
  watch: {
    rawPercentage: function() {
      var raw = this.rawPercentage / 100
      this.ms_bonus.percentage = raw.toFixed(3)
    },
  },
  created() {
    this.axios
      .get(this.$api('medical_services'))
      .then(data => {
        data.data.forEach(MedicalService => {
          this.medical_services.push({
            value: MedicalService.id,
            text: MedicalService.service,
          })
        })
      })
      .catch(error => console.log(error))
  },
  methods: {},
}
</script>
