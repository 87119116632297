<template>
  <div id="msbonusIndex">
    <ms-bonus-table />
  </div>
</template>

<script>
import MsBonusTable from '@/components/ms_bonus/MSBonusTable'
export default {
  components: { MsBonusTable },
  data () {
    return {}
  },
  computed: {
  }
}
</script>
